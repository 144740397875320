module.exports = [{
      plugin: require('/Users/delanielowe/portfolio_gatsby/gatsby-starter-portfolio-emilia/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-external-links","options":{"target":"_blank","rel":"nofollow noopener noreferrer"}}]},
    },{
      plugin: require('/Users/delanielowe/portfolio_gatsby/gatsby-starter-portfolio-emilia/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-47519312-4"},
    },{
      plugin: require('/Users/delanielowe/portfolio_gatsby/gatsby-starter-portfolio-emilia/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/delanielowe/portfolio_gatsby/gatsby-starter-portfolio-emilia/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
